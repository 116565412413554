import { graphql } from "gatsby";
import React from "react";
import ContactForm from "../components/AllModules/ContactForm/ContactForm";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SEO from "../components/seo";
import CareerDetails from "../components/StaticBanner/CareerDetail";

const CareerTemplate = (props) => {
    const { career } = props.data.glstrapi;
    return (
        <div>
            <SEO
                title={career?.Title}
                description={`Know about ${career?.Title.toLowerCase()} job in detail. Apply for the job you like or send us your CV to join us when we have a new and exciting opportunity.`}
                location={props.location}
            />
            <Header/>
            <CareerDetails
                data={career}
            />
            <div id="career-contact-form">
                <ContactForm
                    key="form-2"
                    form_key="career-form-2"
                    career={true}
                    title={career?.Title}
                    formname="SEND US CV"
                    page_type="career-detail"
                />
            </div>
            <Footer 
                popularSearch="Popular_About"
            />
        </div>
    )
}

export default CareerTemplate;

export const pageQuery = graphql`
    query careerQuery($id: ID!) {
        glstrapi {
            career(id: $id, publicationState: LIVE) {
                id
                Meta_Title
                Meta_Description
                URL
                Title
                Sub_Title
                Salary
                Publish
                Location
                Content
                Sort
            }
        }
    }
`