import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col, Modal, Breadcrumb } from "react-bootstrap";
import "./StaticBanner.scss";
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from "html-react-parser";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import { useLocation } from "@reach/router";
import CareerFormPage from "../forms/career-form";
//import LeaderImg from "../../images/module/leader-img01.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CareerDetails = (props) => {
    const [emailModal, setEmailModal] = React.useState(false);
    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + props.url,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    const location = useLocation();
    const handleClick = () => {
        let crEl = document.getElementById("career-contact-form");
        if(crEl != null) {
            const y = crEl.getBoundingClientRect().top - 150;
            window.scroll({
                top: y,
                behavior: 'smooth'
            });
        }
    }
    return (
        <React.Fragment>
            <div className="static-detail-sec">
                <Container fluid className="px-xl-5 px-lg-4 px-3">
                    <Breadcrumb className="mb-0 pb-0">
                        <Breadcrumb.Item>
                            <Link to="/">
                                home
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <Link to="/real-estate-job-vacancies/">
                                careers
                            </Link>
                        </Breadcrumb.Item> 
                        <Breadcrumb.Item active>
                            {props?.data?.Title}
                        </Breadcrumb.Item> 
                        {/* <Breadcrumb.Item></Breadcrumb.Item>  */}
                    </Breadcrumb>
                </Container>
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <h1 className="main-title">{props?.data?.Title}</h1>
                                <div className="news-head d-xl-flex align-items-center justify-content-between">
                                    <div className="news-tab">
                                        <a>{props?.data?.Location.toUpperCase()}</a>
                                        <a className="text-uppercase active">{props?.data?.Salary.toUpperCase()}</a>
                                    </div>
                                </div>
                                <div className="news-head d-flex flex-row career-btns">
                                    <a onClick={() => setEmailModal(true)} className="btn subscribe-btn btn-1 me-4">Apply now</a>
                                    <a className="btn subscribe-btn me-0 btn-2" onClick={handleClick}>Send us your CV</a>
                                </div>
                              
                                <div className="news-info d-xl-flex justify-content-between">
                                    <div className="info">
                                        <p>{HTMLReactParser(props?.data?.Content)}</p>
                                    </div>
                                    {<div className="social-details">
                                        <div className="wrp-info d-md-flex justify-content-between d-xl-block">
                                            {/* {props?.data?.Speak_with_Team != null && <div className="contact-agent d-flex justify-content-between"> */}
                                            {/* <div className="">
                                                <img src={LeaderImg} alt="" />
                                            </div> */} 
                                            {/* <div className="contact-agent-info">
                                                    <span className="contact-agent-name d-block">
                                                        {props?.data?.Speak_with_Team.Name}
                                                    </span>
                                                    <span className="auth-txt d-block">{props?.data?.Speak_with_Team?.Designation}</span>
                                                    {props?.data?.Speak_with_Team?.Phone && <Link
                                                        href={`tel: ${props?.data?.Speak_with_Team?.Phone}`}
                                                        className="contact-agent-number"
                                                    >
                                                        {props?.data?.Speak_with_Team?.Phone}
                                                    </Link>}
                                                </div>
                                            </div>} */}
                                            {/* <div className="social-list">
                                                <h6 className="social-title">Share:</h6>
                                                <div className="social-links">
                                                    <a>
                                                        <TwitterShareButton url={location.href} onClick={() => trackerShare("TwitterShareButton")}>
                                                            <i className="icon-twitter-dark"></i>
                                                        </TwitterShareButton>
                                                    </a>
                                                    <a>
                                                        <FacebookShareButton url={location.href} onClick={() => trackerShare("FacebookShareButton")}>
                                                            <i className="icon-facebook-dark"></i>
                                                        </FacebookShareButton>
                                                    </a>
                                                    <a>
                                                        <LinkedinShareButton url={location.href} onClick={() => trackerShare("LinkedinShareButton")}>
                                                            <i className="icon-linkedin-dark"></i>
                                                        </LinkedinShareButton>
                                                    </a>
                                                    <a
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(location.href); 
                                                            toast("URL Copied",{
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: true,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                    })}}><i className="icon-url-dark"></i></a>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>}
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <NewsSuggestions /> */}
            <Modal size="lg" show={emailModal} onHide={() => setEmailModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Apply Now</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CareerFormPage
                            key="form-1"
                            form_key="career-form-1"
                            title={props?.data?.Title}
                            formname="CAREER APPLY NOW"
                        />
                    </Modal.Body>
                </div>
            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={500}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
            />
{/* Same as */}
<ToastContainer />
        </React.Fragment>
    );
};
export default CareerDetails;
